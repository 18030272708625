import styles from './dropdown.module.scss';
import { getEmployees, getClients, filterActiveClients } from '../../Services/api.service';
import { useEffect, useState } from 'react';

const Dropdown = (props) => {
    const [dropdownItems, setDropdownItems] = useState([]);

    useEffect(() => {
        (async () => {
            const result = await functionMap[props.options]();
            setDropdownItems(result || []);
        })();
    }, [props.options]);

    return (
        <div className={styles.container}>
            <p>
                <b>{props.label}</b>
            </p>
            <select value={props.value} onChange={props.onChange} disabled={props.disabled}>
                <option value={-1} disabled='disabled'>
                    Select {props.label}
                </option>
                {dropdownItems.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item[props.displayKey[0]] && item[props.displayKey[1]]
                            ? item[props.displayKey[0]] + ' - ' + item[props.displayKey[1]]
                            : item[props.displayKey[0]]
                            ? item[props.displayKey[0]]
                            : item[props.displayKey[1]]}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;

const serviceTypes = () => {
    return [
        {
            id: 'Janitorial',
        },
        {
            id: 'Contracting',
        },
    ];
};

const serviceStatuses = () => {
    return [
        {
            id: 0,
            option: 'Scheduled',
        },
        {
            id: 1,
            option: 'Complete',
        },
        {
            id: 2,
            option: 'Cancelled',
        },
    ];
};

const functionMap = {
    getEmployees: getEmployees,
    getClients: getClients,
    serviceTypes: serviceTypes,
    serviceStatuses: serviceStatuses,
    filterActiveClients: filterActiveClients,
};
