import { useEffect, useState } from 'react';
import { FloorQuoteState } from '../models';
import { floorQuote } from '../../../../Services/api.service';
import styles from '../CommercialQuote/commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import Button from '../../../../Components/Button/Button';

const FloorQuote = () => {
    const [floor, setFloor] = useState(FloorQuoteState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFloor(FloorQuoteState);
    }, []);

    const submitFloorQuote = async () => {
        if (floorQuoteValidator(floor)) {
            setLoading(true);
            setSubmitted(true);
            await floorQuote(floor)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={`${styles.componentWrapper} ${styles.contractingComponentWrapper}`}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Floor Type To Remove</h3>
                    <select
                        value={floor.removeFloorType}
                        onChange={(e) => setFloor({ ...floor, removeFloorType: e.target.value })}
                    >
                        <FloorOptions />
                    </select>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Floor Type To Install</h3>
                    <select
                        value={floor.installFloorType}
                        onChange={(e) => setFloor({ ...floor, installFloorType: e.target.value })}
                    >
                        <FloorOptions />
                    </select>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Total Area</h3>
                    <input
                        type='text'
                        onChange={(e) => setFloor({ ...floor, totalArea: e.target.value })}
                        value={floor.totalArea}
                        placeholder='Enter area in sqft (required)'
                    />
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setFloor({ ...floor, email: e.target.value.toLowerCase() })
                        }
                        value={floor.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.contractingButton}>
                        <Button
                            onClick={!submitted ? () => submitFloorQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FloorQuote;

const FloorOptions = () => {
    return (
        <>
            <option value='N/A'>N/A</option>
            <option value='Carpet'>Carpet</option>
            <option value='Hardwood'>Hardwood</option>
            <option value='Laminate'>Laminate</option>
            <option value='Tile'>Tile</option>
            <option value='Vinyl'>Vinyl</option>
        </>
    );
};

const floorQuoteValidator = (floor) => {
    let errorText = '';
    if (floor.removeFloorType === 'N/A' && floor.installFloorType === 'N/A') {
        errorText += 'Must select floor type for either install or remove\n';
    }

    if (floor.totalArea === 0) {
        errorText += 'Service area must be greater than 0 sqft\n';
    }

    if (errorText !== '') {
        alert(errorText);
        return false;
    }

    return !!emailValidator(floor.email);
};
