import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderComponent from '../../../../Components/Header/Header';
import FooterComponent from '../../../../Components/Footer/Footer';
import Stories from '../../../../Components/Stories/Stories';
import styles from './services.module.scss';

import Janitorial from '../../../../Data/ClearCleaning.json';
import Contracting from '../../../../Data/ClearContracting.json';
import StoryData from '../../../../Data/Gallery.json';

import CommercialQuote from '../../Quote/CommercialQuote/CommercialQuote';
import StudentQuote from '../../Quote/StudentHouseQuote/StudentQuote';
import ResidentialQuote from '../../Quote/ResidentialQuote/ResidentialQuote';
import CarpetQuote from '../../Quote/CarpetQuote/CarpetQuote';
import PaintQuote from '../../Quote/PaintQuote/PaintQuote';
import FloorQuote from '../../Quote/FloorQuote/FloorQuote';
import { Helmet } from 'react-helmet-async';
import GroutQuote from '../../Quote/GroutQuote/GroutQuote';

// prettier-ignore
const componentDict = {
    'Commercial': <CommercialQuote />,
    'Student Houses': <StudentQuote />,
    'Residential': <ResidentialQuote />,
    'Post Construction': <ResidentialQuote />,
    'Carpet': <CarpetQuote />,
    'Grout': <GroutQuote />,
    'Paint': <PaintQuote />,
    'Floor': <FloorQuote />,
};
const serviceDict = { Janitorial: Janitorial, Contracting: Contracting };

const Services = () => {
    const navigate = useNavigate();
    const { category, title } = useParams();
    const [currentService, setCurrentService] = useState({});
    const [filteredData] = useState(
        // StoryData.filter((s) =>
        //     s.tags.some((tag) => tag === title.replace(/\s/g, '').toLowerCase()),
        // ),
        StoryData.filter((s) => s.type === category),
    );

    useEffect(() => {
        const serviceComponent = serviceDict[category].find(
            (service) => service.serviceName === title,
        );

        if (serviceComponent) {
            setCurrentService(serviceComponent);
        } else {
            navigate(-1);
        }
    }, [navigate, category, title]);

    return (
        <>
            <Helmet>
                <title>{`ClearServices - ${currentService.serviceName} Services`}</title>
                <meta name='description' content={currentService.description && currentService.description.slice(0, 100)} />
                <link rel='canonical' href={'/' + category + '/' + title} />
            </Helmet>

            <HeaderComponent />
            <div className={styles.container}>
                <h1>{`${currentService.serviceName} Services`}</h1>
                <p>{currentService.description}</p>
            </div>

            {filteredData.length > 0 ? <Stories data={filteredData} /> : null}

            {currentService.quoteAvailable ? (
                <div className={styles.quoteSection}>
                    <h1>Instant Quote</h1>
                    {componentDict[title]}
                </div>
            ) : null}
            <FooterComponent />
        </>
    );
};

export default Services;
