import React, { useEffect, useState } from 'react';
import styles from './datepicker.module.scss';

const DatePicker = (props) => {
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
        setTimestamp(props.initialTimestamp);
        // eslint-disable-next-line
    }, []);

    const handleDateChange = (event) => {
        const newDate = new Date(event.target.value);
        if (isDateDisabled(newDate)) {
            return; // Do not update if the date is disabled
        }
        const unixTimeStamp = dateToUnixTimeStamp(event.target.value);
        setTimestamp(unixTimeStamp);
        props.onChange(unixTimeStamp);
    };

    const setTimestamp = (unixTimestamp) => {
        const formattedDateTime = unixTimestampToDateTime(unixTimestamp);
        setFormattedDate(formattedDateTime);
    };

    const formatDisabledDates = () => {
        const { disabledDates } = props;

        if (Array.isArray(disabledDates)) {
            return disabledDates
                .map((date) => {
                    if (Array.isArray(date)) {
                        const [start, end] = date;
                        return `${formatDate(start)} to ${formatDate(end)}`;
                    } else {
                        return formatDate(date);
                    }
                })
                .join(', ');
        }
        return '';
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const isDateDisabled = (date) => {
        const { disabledDates } = props;
        if (!Array.isArray(disabledDates)) return false;

        const formattedDate = formatDate(date);

        return disabledDates.some((range) => {
            if (Array.isArray(range)) {
                const [start, end] = range;
                return formattedDate >= formatDate(start) && formattedDate <= formatDate(end);
            }
            return formattedDate === formatDate(range);
        });
    };

    return (
        <div className={styles.inputFieldContainer}>
            <p>
                <b>{props.label}</b>
            </p>
            <div className={`${styles.inputField} ${props.className}`}>
                <input
                    type='datetime-local'
                    value={formattedDate}
                    onChange={handleDateChange}
                    disabled={props.disabled}
                />
            </div>
            {props.disabledDates && (
                <p className={styles.disabledDatesText}>
                    Currently not scheduling any appointments for the dates: {formatDisabledDates()}.
                </p>
            )}
        </div>
    );
};

export default DatePicker;

export const dateToUnixTimeStamp = (dateTimeValue) => {
    return Math.floor(new Date(dateTimeValue).getTime() / 1000);
};

export const unixTimestampToDateTime = (unixTimestamp) => {
    const currentDate = new Date(unixTimestamp * 1000);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
};
