import React, { useEffect, useState } from 'react';
import { getDashboard, updateDashboardData } from '../../../Services/api.service';
import Loader from '../../../Components/Loader/Loader';
import { Chart } from 'react-google-charts';
import styles from './dashboard.module.scss';
import { Helmet } from 'react-helmet-async';
import Button from '../../../Components/Button/Button';

const Dashboard = () => {
    const role = localStorage.getItem('role');
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState([]);

    useEffect(() => {
        (async () => {
            setDashboardData(await getDashboard());
            setLoading(false);
        })();
    }, []);

    const chartHeightCalculator = (data) => {
        const length = data.companyPerformance[0].length * 40;
        return `${length}px`;
    };

    let invoicesPieChart = dashboardData['result']?.['invoicesPieChart']?.map((data) => {
        return (
            <div key={`${data['companyName']} invoicesPieChart`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['companyPerformance']}
                    options={{
                        legend: 'left',
                        title: data['companyName'],
                        is3D: true,
                        sliceVisibilityThreshold: 0.017, // 20%
                    }}
                />
            </div>
        );
    });

    let invoicesMonthly = dashboardData['result']?.['invoicesMonthly']?.map((data) => {
        return (
            <div className={styles.chartContainer} key={`${data['companyName']} invoicesMonthly`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='400px'
                    data={data['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            title: data['companyName'],
                            subtitle: 'Monthly Invoices',
                        },
                    }}
                />
            </div>
        );
    });

    let overallCharts = dashboardData['result']?.['overallCharts']?.map((data) => {
        return (
            <div className={styles.chartContainer} key={`${data['companyName']} overallCharts`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='400px'
                    data={data['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            title: data['companyName'],
                            subtitle: 'Monthly Debit vs Expense',
                        },
                    }}
                />
            </div>
        );
    });

    let atmEtransferYearly = dashboardData['result']?.['atmEtransferYearly']?.map((data) => {
        return (
            <div key={`${data['companyName']} expenseYearly`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['companyPerformance']}
                    options={{
                        legend: 'left',
                        title: data['companyName'],
                        is3D: true,
                        sliceVisibilityThreshold: 0.016, // 20%
                    }}
                />
            </div>
        );
    });

    let atmEtransferCharts = dashboardData['result']?.['atmEtransferCharts']?.map((data) => {
        return (
            <div
                className={styles.chartContainer}
                key={`${data['companyName']} atmEtransferCharts`}
            >
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='400px'
                    data={data['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            title: data['companyName'],
                            subtitle: 'Monthly Atm and E-Transfer',
                        },
                    }}
                />
            </div>
        );
    });

    let eTransferCharts = dashboardData['result']?.['eTransferCharts']?.map((data) => {
        return (
            <div key={`${data['companyName']} eTransferCharts`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['companyPerformance']}
                    options={{
                        legend: 'left',
                        title: data['companyName'],
                        is3D: true,
                        sliceVisibilityThreshold: 0.017, // 20%
                    }}
                />
            </div>
        );
    });

    let expensesChart = dashboardData['result']?.['expensesChart']?.map((data) => {
        return (
            <div className={styles.chartContainer} key={`${data['companyName']} expensesChart`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='Bar'
                    width='100%'
                    height='500px'
                    data={data['companyPerformance']}
                    options={{
                        legend: { position: 'left' },
                        chart: {
                            title: data['companyName'],
                            subtitle: 'Expenses for the past 3 months',
                        },
                    }}
                />
            </div>
        );
    });

    let eTransferLastThreeMonths = dashboardData['result']?.['eTransferLastThreeMonths']?.map(
        (data) => {
            return (
                <div
                    className={styles.chartContainer}
                    key={`${data['companyName']} eTransferLastThreeMonths`}
                >
                    <Chart
                        className={styles.chartWrapper}
                        chartType='Bar'
                        width='100%'
                        height={chartHeightCalculator(data)}
                        data={data['companyPerformance']}
                        options={{
                            legend: { position: 'left' },
                            chart: {
                                title: data['companyName'],
                                subtitle: 'E-Transfers for the past 3 months',
                            },
                        }}
                    />
                </div>
            );
        },
    );

    let expenseYearly = dashboardData['result']?.['expenseYearly']?.map((data) => {
        return (
            <div key={`${data['companyName']} expenseYearly`}>
                <Chart
                    className={styles.chartWrapper}
                    chartType='PieChart'
                    data={data['companyPerformance']}
                    options={{
                        legend: 'left',
                        title: data['companyName'],
                        is3D: true,
                        sliceVisibilityThreshold: 0.016, // 20%
                    }}
                />
            </div>
        );
    });

    function syncDashboardData() {
        setLoading(true);
        updateDashboardData().then(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>ClearServices - Admin</title>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.dashboardWrapper}>
                        <p>{dashboardData['message'] ?? ''}</p>
                        {overallCharts ? (
                            <>
                                <h2>Bank</h2>
                                {overallCharts}
                            </>
                        ) : null}
                        {atmEtransferYearly ? (
                            <>
                                <h2>Atm & E-Transfer</h2>
                                <div className={styles.pieChartWrapper}>{atmEtransferYearly}</div>
                                {atmEtransferCharts}
                            </>
                        ) : null}
                        {eTransferCharts ? (
                            <>
                                <h2>E-Transfer</h2>
                                <div className={styles.pieChartWrapper}>{eTransferCharts}</div>
                                {eTransferLastThreeMonths}
                            </>
                        ) : null}
                        {expensesChart ? (
                            <>
                                <h2>Expenses</h2>
                                <div className={styles.pieChartWrapper}>{expenseYearly}</div>
                                {expensesChart}
                            </>
                        ) : null}
                        {invoicesMonthly ? (
                            <>
                                <h2>Invoices</h2>
                                <div className={styles.pieChartWrapper}>{invoicesPieChart}</div>
                                {invoicesMonthly}
                            </>
                        ) : null}
                        {role === 'Admin' ? (
                            <div className={styles.buttonWrapper}>
                                <Button
                                    className={styles.button}
                                    loading={loading}
                                    buttonText='Reload Data'
                                    onClick={syncDashboardData}
                                />
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};

export default Dashboard;
