import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navigation.module.scss';

import Menu from '../../Assets/Images/Icons/Sidebar/menu-blue.png';
import ClearServices from '../../Assets/Images/Logos/ClearServices Logos/ClearServices.png';
import ClearCleaning from '../../Assets/Images/Logos/ClearServices Logos/ClearCleaning.png';
import ClearContracting from '../../Assets/Images/Logos/ClearServices Logos/ClearContracting.png';
import Phone from '../../Assets/Images/Icons/Social/phone.png';
import Email from '../../Assets/Images/Icons/Social/email.png';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../Recoil/Atoms';
import { syncDb } from '../../Services/api.service';

const Navigation = (props) => {
    const navigate = useNavigate();
    const [hideNavBar, setHideNavBar] = useState(true);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [, setLoading] = useRecoilState(loadingState);
    const currentUrl = window.location.pathname;
    const isAdmin = currentUrl.includes('/admin/') || currentUrl.includes('/employee/');
    const userRoleLevel = localStorage.getItem('roleLevel');

    useEffect(() => {
        if (!isAdmin) {
            const handleScroll = () => {
                if (window.scrollY > 80) {
                    setHideNavBar(false);
                } else {
                    setHideNavBar(true);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
        setHideNavBar(false);
    }, [isAdmin]);

    const handleBackdrop = () => {
        setDisplayBackdrop(!displayBackdrop);
        const body = document.body;
        if (displayBackdrop) {
            body.style.overflow = 'auto';
        } else {
            body.style.overflow = 'hidden';
        }
    };

    let menuRender = props.menuItems.map((m) => {
        const isPrivate = props.type === 'Private';
        const canRender = !isPrivate || (isPrivate && userRoleLevel >= m.role);
    
        return canRender && (
            <h4
                key={m.id}
                onClick={() => {
                    navigate(`${m.url}`);
                    handleBackdrop();
                }}
            >
                {m.name}
            </h4>
        );
    });

    async function syncBtnHandler() {
        handleBackdrop();
        setLoading(true);
        await syncDb(true);
        setLoading(false);
    }

    return (
        <>
            {displayBackdrop ? (
                <>
                    <div className={styles.backDrop} onClick={handleBackdrop} />
                    <div className={styles.sideBar}>
                        <div
                            onClick={() => {
                                navigate('/');
                                handleBackdrop();
                            }}
                            className={styles.sideBarTop}
                        >
                            <img src={ClearCleaning} alt='ClearCleaning' />
                            <img src={ClearServices} alt='ClearServices' />
                            <img src={ClearContracting} alt='ClearContracting' />
                        </div>
                        <div className={styles.sideBarMiddle}>
                            {menuRender}
                            {userRoleLevel > 1 && isAdmin ? <h4 onClick={syncBtnHandler}>Sync</h4> : null}
                        </div>
                        {isAdmin ? null : (
                            <div className={styles.sideBarFooter}>
                                <div className={styles.sideBarFooterItem}>
                                    <a href='tel:+1 226-600-9243'>
                                        <img src={Phone} alt='Phone' />
                                        <h4>+1 226-600-9243</h4>
                                    </a>
                                </div>
                                <div className={styles.sideBarFooterItem}>
                                    <a href='mailto: contact@clearservices.ca'>
                                        <img src={Email} alt='Email' />
                                        <h4>contact@clearservices.ca</h4>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div />
            )}
            <div className={styles.navigationBar}>
                {hideNavBar ? (
                    <div />
                ) : (
                    <>
                        <a href='/'>
                            <img src={ClearServices} alt='ClearServices' />
                        </a>
                    </>
                )}
                {isAdmin ? <h5>{new Date().toDateString()}</h5> : null}
                <img src={Menu} alt='Menu' onClick={handleBackdrop} />
            </div>
        </>
    );
};

export default Navigation;
