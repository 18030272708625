import axios from 'axios';
import { getItem, setItem, getEntity } from './storage.handler';

const apiUrl = process.env.REACT_APP_API_URL;
//#region Authentication

/** Authentication header */

const token = window.localStorage.getItem('token');
axios.defaults.headers = {};
const pathNames = [
    'privacy-policy',
    'gallery',
    'contact-us',
    'services',
    'login',
    'payment',
    'all-quotes',
    'student-special',
    'services-all',
    'schedule',
    '',
];
const path = window.location.pathname.split('/')[1];

if (!token && path !== '/') {
    if (!pathNames.includes(path)) {
        window.location.replace('/');
    }
} else {
    axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    };
}

export const syncDb = async (force = false) => {
    if (localStorage.getItem('syncTs')) {
        const lastSync = new Date(localStorage.getItem('syncTs'));
        const since = Math.abs(new Date() - lastSync) / 36e5;

        if (since > 1 || force) {
            await getDashboard(true);
            await getClients(true);
            await getContracts(true);
            await getEmployees(true);
            await getQuotes(true);
            await getServices(true);
            await getInvoices(true);
            localStorage.setItem('syncTs', new Date());
        }
    }
};

//#endregion

//#region Clients

export const getClient = async (id) => {
    await getClients();
    return getEntity('clients', id);
};

export const getClients = async (force = false) => {
    if (!getItem('clients') || force) {
        await axios.get(apiUrl + `clients/`).then((response) => {
            const clientsWithNoCompany = response.data
                .filter((c) => c.companyName === null)
                .sort((a, b) => a.fullName.localeCompare(b.fullName));
            const companies = response.data
                .filter((c) => c.companyName !== null)
                .sort((a, b) => a.companyName.localeCompare(b.companyName));
            setItem('clients', companies.concat(clientsWithNoCompany));
        });
    }

    return getItem('clients');
};

export const filterActiveClients = async () => {
    await getClients(); // Assuming this populates the local storage
    let clientsFromStorage = getItem('clients'); // Retrieve the clients from local storage

    // Filter the clients to only include active ones
    let activeClients = clientsFromStorage.filter(client => client.isActive);
    return activeClients;
};

export const updateClient = async (id, data) => {
    const response = await axios.put(apiUrl + `client/${id}/`, data);
    await getClients(true);
    return response;
};

export const createClient = async (data) => {
    const response = await axios.post(apiUrl + `clients/create`, data);
    await getClients(true);
    return response;
};

export const deleteClient = async (id) => {
    const response = await axios.delete(apiUrl + `client/delete/${id}/`);
    await getClients(true);
    return response;
};

//#endregion

//#region Contracts

export const getContract = async (id) => {
    await getContracts();
    return getEntity('contracts', id);
};

export const getContracts = async (force = false) => {
    if (!getItem('contracts') || force) {
        await axios
            .get(apiUrl + `contracts/`)
            .then((response) => setItem('contracts', response.data));
    }

    return getItem('contracts');
};

export const updateContract = async (id, data) => {
    const response = await axios.put(apiUrl + `contract/${id}/`, data);
    await getContracts(true);
    return response;
};

export const createContract = async (data) => {
    const response = await axios.post(apiUrl + `contracts/create`, data);
    await getContracts(true);
    return response;
};

export const deleteContract = async (id) => {
    const response = await axios.delete(apiUrl + `contract/delete/${id}`);
    await getContracts(true);
    return response;
};

export const createContractServices = async (id) => {
    const response = await axios.get(apiUrl + `create-contract-services/${id}`);
    await getServices(true);
    return response;
};

export const getGroupedContracts = async (active) => {
    await getContracts();
    return getItem('contracts').filter((contract) => contract.isActive === active);
};

//#endregion

//#region Employees

export const getEmployee = async (id) => {
    getEmployees();
    return getEntity('employees', id);
};

export const getEmployees = async (force = false) => {
    if (!getItem('employees') || force) {
        await axios
            .get(apiUrl + `employees/`)
            .then((response) => setItem('employees', response.data));
    }

    return getItem('employees');
};

export const updateEmployee = async (id, data) => {
    const response = await axios.put(apiUrl + `employee/${id}/`, data);
    await getEmployees(true);
    return response;
};

export const createEmployee = async (data) => {
    const response = await axios.post(apiUrl + `employees/create`, data);
    await getEmployees(true);
    return response;
};

export const deleteEmployee = async (id) => {
    const response = await axios.delete(apiUrl + `employee/delete/${id}`);
    await getEmployees(true);
    return response;
};

//#endregion

//#region Quotes

export const getQuote = async (id) => {
    await getQuotes();
    return getEntity('quotes', id);
};

export const getQuotes = async (force = false) => {
    if (!getItem('quotes') || force) {
        await axios.get(apiUrl + `quotes/`).then((response) => setItem('quotes', response.data));
    }

    return getItem('quotes');
};

export const createQuoteService = async (id) => {
    const response = await axios.get(apiUrl + `createservice-fromquote/${id}`);
    await getServices(true);
    await getClients(true);
    return response;
};

//#endregion

//#region Services

export const getServiceByStatus = async (status) => {
    const statusDict = {
        scheduled: 0,
        complete: 1,
        cancelled: 2,
    };

    await getServices();

    if (status === 'billed') {
        return getItem('services')
            .filter(
                (service) =>
                    service.billed === true &&
                    service.paid === false &&
                    service.serviceStatus === 1,
            )
            .sort((a, b) => {
                return a.invoiceNumber - b.invoiceNumber;
            });
    } else if (status === 'scheduled') {
        return getItem('services')
            .filter((service) => service.serviceStatus === statusDict[status])
            .sort((a, b) => {
                return new Date(a.date + 'T' + a.time) - new Date(b.date + 'T' + b.time);
            });
    } else if (status === 'complete') {
        return getItem('services')
            .filter(
                (service) =>
                    service.serviceStatus === statusDict[status] ||
                    service.serviceStatus === statusDict['cancelled'],
            )
            .sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
    }
};

export const getService = async (id) => {
    await getServices();
    return getEntity('services', id);
};

export const getServices = async (force = false) => {
    if (!getItem('services') || force) {
        await axios
            .get(apiUrl + `services/`)
            .then((response) => setItem('services', response.data));
    }

    return getItem('services');
};

export const updateService = async (id, data) => {
    const response = await axios.put(apiUrl + `service/${id}/`, data);
    await getServices(true);
    return response;
};

export const createService = async (data) => {
    const response = await axios.post(apiUrl + `services/create`, data);
    await getServices(true);
    return response;
};

export const deleteService = async (id) => {
    const response = await axios.delete(apiUrl + `service/delete/${id}`);
    await getServices(true);
    return response;
};

export const duplicateService = async (id) => {
    const response = await axios.post(apiUrl + `duplicate-service/${id}/`);
    await getServices(true);
    return response;
};

//#endregion

//#region Invoices

export const getInvoice = async (id) => {
    await getInvoices();
    return getEntity('invoices', id);
};

export const getInvoices = async (force = false) => {
    if (!getItem('invoices') || force) {
        await axios
            .get(apiUrl + `invoices/`)
            .then((response) => setItem('invoices', response.data));
    }

    return getItem('invoices');
};

export const getAllInvoiceRecords = async () => {
    await axios
        .get(apiUrl + `get-all-invoices/`)
        .then((response) => setItem('invoices', response.data));
    return getItem('invoices');
};

export const receivePayment = async (id) => {
    const response = await axios.put(apiUrl + `receive-payment/${id}/`);
    await getInvoices(true);
    return response;
};

export const followUp = async (id) => {
    const response = await axios.get(apiUrl + `invoice-follow-up/${id}`);
    return response;
};

export const createInvoice = async (data) => {
    const response = await axios.post(apiUrl + `invoices/create`, data);
    await getInvoices(true);
    return response;
};

export const deleteInvoice = async (id) => {
    const response = await axios.delete(apiUrl + `invoice/delete/${id}/`);
    await getInvoices(true);
    return response;
};

export const dailyUpdate = async () => {
    await axios.get(apiUrl + `daily-update`);
};

export const downloadInvoiceFile = async (invoiceNumber) => {
    return await axios.get(apiUrl + `download-invoice/${invoiceNumber}`, {
        responseType: 'blob',
    });
};
//#endregion

//#region TodaysService

export const todaysService = async (secret, force = false) => {
    if (!getItem('todaysService') || force) {
        await axios
            .get(apiUrl + `todays-services-summary/${secret}`)
            .then((response) => {
                if (response.status === 200) {
                    setItem('todaysService', response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    setItem('todaysService', []);
                }
            });
    }

    return getItem('todaysService');
};

//#endregion

//#region Quotes

export const commercialQuote = async (data) => {
    return axios.post(apiUrl + `commercial-quote/`, data);
};

export const residentialQuote = async (data) => {
    return axios.post(apiUrl + `residential-quote/`, data);
};

export const carpetQuote = async (data) => {
    return axios.post(apiUrl + `carpet-quote/`, data);
};

export const studentQuote = async (data) => {
    return axios.post(apiUrl + `student-quote/`, data);
};

export const groutQuote = async (data) => {
    return axios.post(apiUrl + `grout-quote/`, data);
};

export const paintQuote = async (data) => {
    return axios.post(apiUrl + `paint-quote/`, data);
};

export const floorQuote = async (data) => {
    return axios.post(apiUrl + `floor-quote/`, data);
};

export const quoteGenerator = async (data) => {
    const response = axios.post(apiUrl + `generate-quote/`, data);
    await getQuotes(true);
    return response;
};

//#endregion

//#region Contact Us

export const contactFormSubmit = async (data) => {
    return axios.post(apiUrl + `contact/`, data);
};

//#endregion

//#region Dashboard

export const getDashboard = async (force = false) => {
    if (!getItem('dashboard') || force) {
        await axios
            .get(apiUrl + `generate-chart-data/`)
            .then((response) => setItem('dashboard', response.data));
    }

    return getItem('dashboard');
};

export const updateDashboardData = async () => {
    await axios.get(apiUrl + `sync-dashboard-data`);
};

//#endregion

//#region Payment

export const checkQuoteInvoice = async (data) => {
    return axios.post(apiUrl + `check-quote-invoice/`, data);
};

export const processPayment = async (data) => {
    return axios.post(apiUrl + `process-payment/`, data);
};

//#endregion