import { useEffect, useState } from 'react';
import { commercialState } from '../models';
import { commercialQuote } from '../../../../Services/api.service';
import plus from '../../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../../Assets/Images/Icons/Other/minus.png';
import styles from './commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import Button from '../../../../Components/Button/Button';

const CommercialQuote = () => {
    const [commercial, setCommercial] = useState(commercialState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCommercial(commercialState);
    }, []);

    const submitCommercialQuote = async () => {
        if (commercialValidator(commercial.days, commercial.frequency, commercial.email)) {
            setLoading(true);
            setSubmitted(true);
            await commercialQuote(commercial)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={styles.componentWrapper}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Hours</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (commercial.hours > 1) {
                                    setCommercial({ ...commercial, hours: commercial.hours - 0.5 });
                                }
                            }}
                            alt='minus'
                        />
                        <input type='text' value={commercial.hours} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (commercial.hours < 8) {
                                    setCommercial({ ...commercial, hours: commercial.hours + 0.5 });
                                }
                            }}
                            alt='plus'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Days</h3>
                    <div className={styles.dayWrapper}>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: { ...commercial.days, Monday: !commercial.days.Monday },
                                })
                            }
                        >
                            <h5>Monday</h5>
                            <input type='checkbox' checked={commercial.days.Monday} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: { ...commercial.days, Tuesday: !commercial.days.Tuesday },
                                })
                            }
                        >
                            <h5>Tuesday</h5>
                            <input type='checkbox' checked={commercial.days.Tuesday} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: {
                                        ...commercial.days,
                                        Wednesday: !commercial.days.Wednesday,
                                    },
                                })
                            }
                        >
                            <h5>Wednesday</h5>
                            <input type='checkbox' checked={commercial.days.Wednesday} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: {
                                        ...commercial.days,
                                        Thursday: !commercial.days.Thursday,
                                    },
                                })
                            }
                        >
                            <h5>Thursday</h5>
                            <input type='checkbox' checked={commercial.days.Thursday} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: { ...commercial.days, Friday: !commercial.days.Friday },
                                })
                            }
                        >
                            <h5>Friday</h5>
                            <input type='checkbox' checked={commercial.days.Friday} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: {
                                        ...commercial.days,
                                        Saturday: !commercial.days.Saturday,
                                    },
                                })
                            }
                        >
                            <h5>Saturday</h5>
                            <input type='checkbox' checked={commercial.days.Saturday} readOnly />
                        </div>
                        <div
                            className={styles.dayRows}
                            onClick={() =>
                                setCommercial({
                                    ...commercial,
                                    days: { ...commercial.days, Sunday: !commercial.days.Sunday },
                                })
                            }
                        >
                            <h5>Sunday</h5>
                            <input type='checkbox' checked={commercial.days.Sunday} readOnly />
                        </div>
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Repeat</h3>

                    <select
                        value={commercial.frequency}
                        onChange={(e) =>
                            setCommercial({ ...commercial, frequency: e.target.value })
                        }
                    >
                        <FrequencyDropDown />
                    </select>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setCommercial({ ...commercial, email: e.target.value.toLowerCase() })
                        }
                        value={commercial.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.janitorialButton}>
                        <Button
                            onClick={!submitted ? () => submitCommercialQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommercialQuote;

const FrequencyDropDown = () => {
    return (
        <>
            <option value='none' disabled='disabled'>
                Select
            </option>
            <option value='weekly'>Weekly</option>
            <option value='biWeekly'>Bi-Weekly</option>
            <option value='monthly'>Monthly</option>
        </>
    );
};

const commercialValidator = (dayDict, repeat, email) => {
    if (!dayValidator(dayDict)) {
        return false;
    }

    if (repeat === 'none') {
        alert('Select how often to repeat this service.');
        return false;
    }

    return !!emailValidator(email);
};

const dayValidator = (dayDictionary) => {
    for (let key in dayDictionary) {
        if (dayDictionary[key] === true) {
            return true;
        }
    }
    alert('Must select a day.');
    return false;
};
