import React, { useEffect, useState } from 'react';
import { contactFormSubmit } from '../../../Services/api.service';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import Button from '../../../Components/Button/Button';
import InputField from '../../../Components/InputField/InputField';
import styles from './contact.module.scss';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
    const [mail, setMail] = useState(MailState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMail(MailState);
    }, []);

    const submitForm = async () => {
        if (contactFormValidator(mail)) {
            setLoading(true);
            setSubmitted(true);
            await contactFormSubmit(mail)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem submitting your request.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>ClearServices - Contact Us</title>
                <meta name='description' content='Contact us for a feedback, help or to join our team.' />
                <link rel='canonical' href='/contact-us' />
            </Helmet>
            <Header />
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1>Get in touch!</h1>
                    <p>
                        Contact us for a feedback, help or to join our team.
                        <br />
                        <br />
                        <b>+1 226-600-9243</b>
                        <br />
                        <br />
                        <b>contact@clearservices.ca</b>
                    </p>
                </div>
                <InputField
                    label='Name'
                    placeholder='Enter name'
                    onChange={(e) => setMail({ ...mail, name: e.target.value })}
                    value={mail.name}
                />
                <InputField
                    label='Email'
                    placeholder='Enter email'
                    onChange={(e) => setMail({ ...mail, email: e.target.value })}
                    value={mail.email}
                />
                <InputField
                    label='Subject'
                    placeholder='Enter subject'
                    onChange={(e) => setMail({ ...mail, subject: e.target.value })}
                    value={mail.subject}
                />
                <InputField
                    label='Phone'
                    placeholder='Enter phone'
                    onChange={(e) => setMail({ ...mail, phone: e.target.value })}
                    value={mail.phone}
                />
                <div className={styles.messageArea}>
                    <p>
                        <b>Message</b>
                    </p>
                    <textarea
                        placeholder='Enter message'
                        onChange={(e) => setMail({ ...mail, message: e.target.value })}
                        value={mail.message}
                    />
                </div>
                <Button
                    buttonText='Submit'
                    submitted={submitted}
                    loading={loading}
                    responseMessage={responseMessage}
                    onClick={submitForm}
                />
            </div>

            <Footer />
        </>
    );
};

export default Contact;

const MailState = {
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
};

export const emailValidator = (email) => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email))
    {
        alert('You must enter a valid email address.');
        return false;
    }
    return true;
};

export const contactFormValidator = (mail) => {
    if (!emailValidator(mail.email)) {
        return false;
    }

    if (mail.message === '') {
        alert("Message body can't be blank");
        return false;
    }

    return true;
};
