import { useEffect, useState } from 'react';
import { GroutQuoteState } from '../models';
import { groutQuote } from '../../../../Services/api.service';
import plus from '../../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../../Assets/Images/Icons/Other/minus.png';
import styles from '../CommercialQuote/commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import Button from '../../../../Components/Button/Button';

const GroutQuote = () => {
    const [grout, setGrout] = useState(GroutQuoteState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setGrout(GroutQuoteState);
    }, []);

    const submitGroutQuote = async () => {
        if (groutQuoteValidator(grout)) {
            setLoading(true);
            setSubmitted(true);
            await groutQuote(grout)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={styles.componentWrapper}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Rooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (grout.room > 0) {
                                    setGrout({ ...grout, room: grout.room - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={grout.room} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (grout.room < 20) {
                                    setGrout({ ...grout, room: grout.room + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Bathrooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (grout.bathroom > 0) {
                                    setGrout({ ...grout, bathroom: grout.bathroom - 0.5 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={grout.bathroom} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (grout.bathroom < 8) {
                                    setGrout({ ...grout, bathroom: grout.bathroom + 0.5 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Hallways</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (grout.hallway > 0) {
                                    setGrout({ ...grout, hallway: grout.hallway - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={grout.hallway} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (grout.hallway < 5) {
                                    setGrout({ ...grout, hallway: grout.hallway + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Kitchen</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (grout.kitchen > 0) {
                                    setGrout({ ...grout, kitchen: grout.kitchen - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={grout.kitchen} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (grout.kitchen < 4) {
                                    setGrout({ ...grout, kitchen: grout.kitchen + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Shower</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (grout.shower > 0) {
                                    setGrout({ ...grout, shower: grout.shower - 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                        <input type='text' value={grout.shower} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (grout.shower < 4) {
                                    setGrout({ ...grout, shower: grout.shower + 1 });
                                }
                            }}
                            alt='adjust-icon'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setGrout({ ...grout, email: e.target.value.toLowerCase() })
                        }
                        value={grout.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.janitorialButton}>
                        <Button
                            onClick={!submitted ? () => submitGroutQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroutQuote;

const groutQuoteValidator = (grout) => {
    if (
        grout.room === 0 &&
        grout.bathroom === 0 &&
        grout.hallway === 0 &&
        grout.kitchen === 0 &&
        grout.shower === 0
    ) {
        alert('Must meet minimum service requirements.');
        return false;
    }

    return !!emailValidator(grout.email);
};
