import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    deleteInvoice,
    getInvoice,
    receivePayment,
    downloadInvoiceFile,
    followUp,
} from '../../../../Services/api.service';
import styles from './invoice-details.module.scss';
import {
    dateToUnixTimeStamp,
    unixTimestampToDateTime,
} from '../../../../Components/DatePicker/DatePicker';
import Loader from '../../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

function InvoiceDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const [id] = useState(params.id);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [clientName, setClientName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [subTotal, setSubTotal] = useState('');
    const [tax, setTax] = useState('');
    const [total, setTotal] = useState('');
    const [paid, setPaid] = useState(false);
    const [paidDateTs, setPaidDateTs] = useState('');
    const [type, setType] = useState('');
    const [lineData, setLineData] = useState({});
    const [loading, setLoading] = useState(true);

    const payData = {
        paid: paid,
        paidDateTs: paidDateTs,
    };

    useEffect(() => {
        (async () => {
            const currentInvoice = await getInvoice(id);
            setClientName(currentInvoice.clientName);
            setCompanyName(currentInvoice.companyName);
            setInvoiceNumber(currentInvoice.invoiceNumber);
            setType(currentInvoice.type);
            setSubTotal(currentInvoice.subTotal);
            setTax(currentInvoice.tax);
            setTotal(currentInvoice.total);
            setPaid(currentInvoice.paid);
            setPaidDateTs(currentInvoice.paidDateTs);
            setLineData(JSON.parse(currentInvoice.lineData));
            setLoading(false);
        })();
    }, [id]);

    function receivePay() {
        payData.paid = true;
        payData.paidDateTs = dateToUnixTimeStamp(new Date().getTime());

        setLoading(true);
        receivePayment(id, payData).then((response) => {
            if (response.status === 200) {
                navigate(-1);
            }
        });
    }

    function followUpInvoice() {
        setLoading(true);
        followUp(id).then((response) => {
            if (response.status === 200) {
                navigate(-1);
            }
        });
    }

    function deleteHandle() {
        const confirmBox = window.confirm('Do you really want to delete this record?');
        if (confirmBox === true) {
            setLoading(true);
            deleteInvoice(id).then((response) => {
                if (response.status === 204) {
                    navigate(-1);
                }
            });
        }
    }

    async function downloadInvoice() {
        setLoading(true);
        try {
            const response = await downloadInvoiceFile(invoiceNumber);
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = response.headers['content-disposition'].split('=')[1].replace(/"/g, '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
        setLoading(false);
    }

    let lineItemsFirstColumn = lineData.columns?.map((data, index) => {
        return <th key={index}>{data}</th>;
    });

    let lineItems = lineData.data?.map((row, index) => {
        return (
            <tr key={index}>
                {lineData.columns.map((header) => (
                    <td key={header}>{row[header]}</td>
                ))}
            </tr>
        );
    });

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.container}>
                        <p>{companyName ? companyName : clientName}</p>
                        <p>
                            {paid ? `Paid on ${unixTimestampToDateTime(paidDateTs)}` : 'Not Paid'}
                        </p>
                        <p>
                            # {invoiceNumber} - {type}
                        </p>
                    </div>

                    <div className={styles.formContainer}>
                        <div>
                            <p>Invoice Number *</p>
                            <input disabled type='text' value={invoiceNumber} />
                        </div>

                        <div>
                            <p>Sub total</p>
                            <input disabled type='text' value={subTotal} />
                        </div>

                        <div>
                            <p>Tax</p>
                            <input disabled type='text' value={tax} />
                        </div>

                        <div>
                            <p>Total</p>
                            <input disabled type='text' value={total} />
                        </div>

                        <div className={styles.tableWrapper}>
                            <table>
                                <thead>
                                    <tr>{lineItemsFirstColumn}</tr>
                                </thead>
                                <tbody>{lineItems}</tbody>
                            </table>
                        </div>

                        <div className={styles.totalDisplayContainer}>
                            <h4>Sub-total {subTotal}</h4>
                            <h4>Tax {tax}</h4>
                            <h3>Total {total}</h3>
                        </div>
                    </div>

                    <div className={styles.formButton}>
                        <button onClick={followUpInvoice}>Follow Up</button>
                        <button onClick={receivePay}>Paid</button>
                        <button onClick={downloadInvoice}>Download</button>
                        <button onClick={deleteHandle}>Delete</button>
                    </div>
                </>
            )}
        </>
    );
}

export default InvoiceDetails;
