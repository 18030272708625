import { useEffect, useState } from 'react';
import { CarpetState } from '../models';
import { carpetQuote } from '../../../../Services/api.service';
import plus from '../../../../Assets/Images/Icons/Other/plus.png';
import minus from '../../../../Assets/Images/Icons/Other/minus.png';
import styles from '../CommercialQuote/commercial-quote.module.scss';
import { emailValidator } from '../../Contact/Contact';
import DatePicker, { dateToUnixTimeStamp } from '../../../../Components/DatePicker/DatePicker';
import Button from '../../../../Components/Button/Button';

const CarpetQuote = () => {
    const [carpet, setCarpet] = useState(CarpetState);
    const [submitted, setSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCarpet(CarpetState);
    }, []);

    const submitCarpetQuote = async () => {
        if (carpetQuoteValidator(carpet)) {
            setLoading(true);
            setSubmitted(true);
            await carpetQuote(carpet)
                .then((response) => {
                    if (response.status === 200) {
                        setResponseMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 405) {
                        setResponseMessage(error.response.data.message);
                    } else {
                        setResponseMessage('There was a problem generating your quote.');
                    }
                });
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{ pointerEvents: loading ? 'none' : 'auto' }}
                className={styles.componentWrapper}
            >
                <div className={styles.sectionWrapper}>
                    <h3>Date</h3>
                    <DatePicker
                        className={styles.datePicker}
                        initialTimestamp={dateToUnixTimeStamp(new Date())}
                        onChange={(unixTimeStamp) =>
                            setCarpet({
                                ...carpet,
                                timestamp: unixTimeStamp,
                            })
                        }
                    />
                    <p className={styles.rushServiceWarningText}>
                        *An additional fee for a rush service will be charged if the service
                        required is within the following 72 hours.
                    </p>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Rooms</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (carpet.room > 0) {
                                    setCarpet({ ...carpet, room: carpet.room - 1 });
                                }
                            }}
                            alt='minus'
                        />
                        <input type='text' value={carpet.room} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (carpet.room < 15) {
                                    setCarpet({ ...carpet, room: carpet.room + 1 });
                                }
                            }}
                            alt='plus'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Staircases</h3>
                    <div className={styles.adjusterWrapper}>
                        <img
                            src={minus}
                            onClick={() => {
                                if (carpet.stairCase > 0) {
                                    setCarpet({ ...carpet, stairCase: carpet.stairCase - 1 });
                                }
                            }}
                            alt='minus'
                        />
                        <input type='text' value={carpet.stairCase} disabled />
                        <img
                            src={plus}
                            onClick={() => {
                                if (carpet.stairCase < 5) {
                                    setCarpet({ ...carpet, stairCase: carpet.stairCase + 1 });
                                }
                            }}
                            alt='plus'
                        />
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Other</h3>
                    <div className={styles.dayWrapper}>
                        <div
                            className={styles.dayRows}
                            onClick={() => setCarpet({ ...carpet, basement: !carpet.basement })}
                        >
                            <h5>Basement</h5>
                            <input type='checkbox' checked={carpet.basement} readOnly />
                        </div>
                    </div>
                </div>

                <div className={styles.sectionWrapper}>
                    <h3>Email</h3>
                    <input
                        type='text'
                        onChange={(e) =>
                            setCarpet({ ...carpet, email: e.target.value.toLowerCase() })
                        }
                        value={carpet.email}
                        placeholder='Enter email (required)'
                    />
                    <div className={styles.janitorialButton}>
                        <Button
                            onClick={!submitted ? () => submitCarpetQuote() : null}
                            buttonText='Submit'
                            submitted={submitted}
                            loading={loading}
                            responseMessage={responseMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarpetQuote;

const carpetQuoteValidator = (carpet) => {
    if (carpet.room === 0 && carpet.stairCase === 0 && !carpet.basement) {
        alert('Must meet minimum service requirements.');
        return false;
    }

    return !!emailValidator(carpet.email);
};
