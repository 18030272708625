import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dailyUpdate, getInvoices, getAllInvoiceRecords } from '../../../../Services/api.service';
import check from '../../../../Assets/Images/Icons/Other/checkmark.png';
import xmark from '../../../../Assets/Images/Icons/Other/xmark.png';
import styles from './invoice-view.module.scss';

import { unixTimestampToDateTime } from '../../../../Components/DatePicker/DatePicker';
import Loader from '../../../../Components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

function Invoice() {
    const storedKeyword = localStorage.getItem('searchKeyword') || '';
    const storedViewUnpaidStatus = localStorage.getItem('unpaidStatus') === 'true';
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [invoicesFiltered, setInvoicesFiltered] = useState([]);
    const [viewUnpaid, setViewUnpaid] = useState(storedViewUnpaidStatus);
    const [kwargs, setKwargs] = useState(storedKeyword);
    const [loading, setLoading] = useState(true);
    const [screenWidth] = useState(window.innerWidth);
    const [characterCount, setCharacterCount] = useState(23);

    // Update storage whenever viewUnpaid changes
    useEffect(() => {
        localStorage.setItem('unpaidStatus', viewUnpaid);
        if (viewUnpaid) {
            setInvoicesFiltered(invoices.filter((i) => i.billed === true && i.paid === false));
        } else {
            setInvoicesFiltered(invoices);
        }
    }, [invoices, viewUnpaid]);

    useEffect(() => {
        (async () => {
            setInvoices(await getInvoices());
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (screenWidth > 1200) {
            setCharacterCount(30);
        }

        if (screenWidth > 1400) {
            setCharacterCount(45);
        }
    }, [screenWidth]);

    useEffect(() => {
        const storedValue = localStorage.getItem('searchKeyword');
        if (storedValue) {
            setKwargs(storedValue);
        }
    }, []);

    // Update storage whenever inputValue changes
    useEffect(() => {
        localStorage.setItem('searchKeyword', kwargs);
    }, [kwargs]);

    let filteredData = invoiceSearch(invoicesFiltered, kwargs);

    let dataRender = filteredData.map((data) => {
        return (
            <tr key={data.id}>
                <td
                    className='pointer'
                    onClick={() => {
                        navigate(`/admin/invoices/${data.id}`);
                    }}
                >
                    <h4># {data.invoiceNumber + ' - ' + data.type}</h4>
                </td>
                <td>{unixTimestampToDateTime(data.dateTs)}</td>
                <td onClick={() => navigate(`/admin/clients/${data.clientId}`)} colSpan={2}>
                    {truncateText(
                        data.companyName ? data.companyName : data.clientName,
                        characterCount,
                    )}
                </td>
                <td>{data.total}</td>
                <td>
                    {data.billed ? (
                        <img loading='lazy' src={check} alt='icon' />
                    ) : (
                        <img loading='lazy' src={xmark} alt='icon' />
                    )}
                </td>
                <td>
                    {data.paid ? (
                        <img loading='lazy' src={check} alt='icon' />
                    ) : (
                        <img loading='lazy' src={xmark} alt='icon' />
                    )}
                </td>
            </tr>
        );
    });

    async function dailyUpdateCall() {
        setLoading(true);
        await dailyUpdate();
        setInvoices(await getInvoices(true));
        setLoading(false);
    }

    async function getAllInvoices() {
        setLoading(true);
        setInvoices(await getAllInvoiceRecords());
        setLoading(false);
    }

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <h2 className={styles.centerAlign}>
                        Invoices <br /> {invoiceTotal(filteredData, kwargs)}
                    </h2>
                    <div className={styles.searchContainer}>
                        <input
                            placeholder='Search...'
                            value={kwargs}
                            onChange={(e) => setKwargs(kwargsFixer(e.target.value))}
                        />
                        <div>
                            <button onClick={() => navigate('/admin/invoices/invoice-generator')}>
                                Create
                            </button>
                            <button onClick={getAllInvoices}>Load All</button>
                            <button onClick={dailyUpdateCall}>Daily Update</button>
                        </div>
                    </div>

                    <div className={styles.tableWrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Invoice</th>
                                    <th>Billed Date</th>
                                    <th colSpan={2}>Client</th>
                                    <th>Total</th>
                                    <th>Billed</th>
                                    <th onClick={() => setViewUnpaid(!viewUnpaid)}>Paid</th>
                                </tr>
                            </thead>
                            <tbody>{dataRender}</tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
}

export default Invoice;

export const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

const invoiceSearch = (targetList, kwargs) => {
    return targetList.filter(
        (invoice) =>
            invoice.invoiceNumber?.toString().match(kwargs) ||
            invoice.date?.match(kwargs) ||
            invoice.total?.toString().match(kwargs) ||
            invoice.type?.toLowerCase().match(kwargs) ||
            invoice.clientName?.toLowerCase().match(kwargs) ||
            invoice.companyName?.toLowerCase().match(kwargs),
    );
};

const invoiceTotal = (invoiceList, kwargs) => {
    let total = 0;
    let subTotal = 0;
    let tax = 0;

    if (kwargs === null || kwargs === '') {
        invoiceList
            .filter((i) => i.paid === false)
            .forEach((invoice) => {
                total += parseFloat(invoice.total);
            });
    } else {
        invoiceList.forEach((invoice) => {
            total += parseFloat(invoice.total);
        });
    }

    subTotal = total / 1.13;
    tax = subTotal * 0.13;

    return (
        <p>
            Sub-total: {currencyFormat.format(subTotal)}
            <br />
            Tax: {currencyFormat.format(tax)}
            <br />
            Total: {currencyFormat.format(total)}
        </p>
    );
};

const kwargsFixer = (kwarg) => {
    return kwarg.replace(/[^a-zA-Z0-9- ]/g, '').toLowerCase();
};

const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
