import React from 'react';
import styles from './footer.module.scss';
import publicNavigationList from '../../Data/NavigationPublic.json';

import Trushield from '../../Assets/Images/Icons/Insurance/insurance.png';
import Wsib from '../../Assets/Images/Icons/Insurance/wsib.png';

import Facebook from '../../Assets/Images/Icons/Social/facebook.png';
import Instagram from '../../Assets/Images/Icons/Social/instagram.png';
import Google from '../../Assets/Images/Icons/Social/google.png';

import Visa from '../../Assets/Images/Icons/Other/visa.png';
import MasterCard from '../../Assets/Images/Icons/Other/mastercard.png';
import Interac from '../../Assets/Images/Icons/Other/interac.png';

const Footer = () => {
    const renderLinks = () => {
        if (publicNavigationList) {
            return (
                <>
                    {publicNavigationList.map((item, index) => (
                        index === 3 ? (
                            <React.Fragment key={item.url}>
                                <a href={item.url}>
                                    {item.name}&nbsp;&nbsp;
                                    <br />
                                </a>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={item.url}>
                                <a href={item.url}>
                                    {item.name}&nbsp;&nbsp;|&nbsp;&nbsp;
                                </a>
                            </React.Fragment>
                        )
                    ))}
                </>
            );
        }
    };

    return (
        <div className={styles.footer}>
            <div className={styles.footerInner}>
                <p>
                    *ClearServices, ClearCleaning and ClearContracting are trade names used and
                    operated by Clear Services Group Inc.
                </p>
            </div>

            <div className={styles.insuranceWrapper}>
                <img src={Trushield} alt='Trushield' />
                <p>
                    We provide up to $5,000,000 in TruShield insurance coverage to protect your home
                    or business against any potential damages that may occur during our services.
                </p>
                <img src={Wsib} alt='Wsib' />
                <p>
                    All team members undergo a screening process that includes a check of their
                    criminal records and references.
                </p>
            </div>

            <div id='Contact' className={styles.middleLayer}>
                <div className={styles.contactSection}>
                    <p>+1 226-600-9243</p>
                    <p>contact@clearservices.ca</p>
                    <div className={styles.socialMedia}>
                        <a
                            href='https://www.facebook.com/clearservicesofficial'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src={Facebook} alt='Facebook' />
                        </a>
                        <a
                            href='https://www.instagram.com/clearservicesofficial'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src={Instagram} alt='Instagram' />
                        </a>
                        <a
                            href='https://g.page/r/CaYXQ69ei5mQEBM/review'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src={Google} alt='Google' />
                        </a>
                    </div>
                    <a className={styles.acceptedPayments} href='/payment'>
                        <p>We accept</p>
                        <div className={styles.acceptedPaymentsIcons}>
                            <img src={Visa} alt='Visa' />
                            <img src={MasterCard} alt='MasterCard' />
                            <img src={Interac} alt='Interac' />
                        </div>
                    </a>
                </div>
                <div className={styles.mapWrapper}>
                    <iframe
                        className={styles.iframe}
                        title='ZoneMap'
                        src='https://www.google.com/maps/d/embed?mid=1pw6RagtjWQPelvEmc_IUJksjCUL8zUFL&ehbc=2E312F&z=8'
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.subLinks}>
                    {renderLinks()}
                    <a key={'/privacy-policy'} href='/privacy-policy'>Privacy Policy</a>
                </div>
                <br />
                <div className={styles.copyright}>
                    <p>
                        Copyright © {new Date().getFullYear()} Clear Services Group Inc. All rights
                        reserved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
