const kst1 = {
    1: 4,
    2: 4,
    3: 3,
    4: 4,
    5: 6,
    6: 5,
    7: 4,
    8: 4,
};

const kst2 = {
    1: 6,
    2: 5,
    3: 5,
    4: 5,
};

const listOfDictionaries = {
    kst1: kst1,
    kst2: kst2,
    other: 5,
};

export const kingStreetTowerOne = () => {
    let result = [];

    for (let floor = 1; floor < 18; floor += 1) {
        let units = 9;

        if (floor === 1) {
            units = 3;
        }

        if (floor === 17) {
            units = 7;
        }

        for (let unit = 1; unit < units; unit++) {
            result.push(floor + '0' + unit);
        }
    }

    return result;
};

export const kingStreetTowerTwo = () => {
    let result = [];

    for (let floor = 2; floor < 22; floor += 1) {
        let units = 5;

        for (let unit = 1; unit < units; unit++) {
            result.push(floor + '0' + unit);
        }
    }

    return result;
};

export const bridgeportHouse = () => {
    let result = [];

    for (let floor = 1; floor < 19; floor += 1) {
        let units = 7;

        if (floor === 1) {
            units = 2;
        }

        if (floor === 13) {
            units = 0;
        }

        if (floor === 18) {
            units = 5;
        }

        for (let unit = 1; unit < units; unit++) {
            result.push(floor + '0' + unit);
        }
    }

    return result;
};

export const prestonHouse = () => {
    let result = [];

    for (let floor = 2; floor < 19; floor += 1) {
        let units = 5;

        if (floor === 13) {
            units = 0;
        }

        if (floor === 18) {
            units = 3;
        }

        for (let unit = 1; unit < units; unit++) {
            result.push(floor + '0' + unit);
        }
    }

    return result;
};

export const myRez = () => {
    let result = [];

    for (let floor = 2; floor < 18; floor += 1) {
        let units = 7;

        if (floor === 17) {
            units = 6;
        }

        for (let unit = 1; unit < units; unit++) {
            result.push(floor + '0' + unit);
        }
    }

    return result;
};

export const getRoomNumber = (building, unit) => {
    if (building && unit) {
        unit = parseInt(unit.slice(-1));
        if (building === 'kst1') {
            return listOfDictionaries[building][unit];
        } else if (building === 'kst2') {
            return listOfDictionaries[building][unit];
        } else {
            return listOfDictionaries['other'];
        }
    }
};
